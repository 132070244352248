<template>
  <div>
    <el-dialog
      title="通知"
      :visible="true"
      width="400px"
      :close-on-click-modal="false"
      @close="$emit('cancel'), $emit('close')"
    >
      <div style="color: #2D271B">
        開啟簡易註冊，註冊步驟將省去「填寫會員基本資料」。
      </div>

      <div slot="footer">
        <el-button plain @click="$emit('cancel'), $emit('close')">取消</el-button>
        <el-button type="primary" @click="$emit('confirm'), $emit('close')">儲存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'AutoInvoiceDialog',
}
</script>

<style></style>
