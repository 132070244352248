<template>
  <div>
    <el-dialog
      title="組織資料設定"
      :visible="true"
      width="670px"
      :close-on-click-modal="false"
      @close="$emit('close')"
    >
      <el-form ref="form" :model="formData" :rules="formRules">
        <el-form-item>
          <div>
            <p>Logo 設定</p>
            <p>建議圖片比例為1:1，尺寸125x125像素</p>
          </div>
          <UploadButton
            :img="formData.img"
            :isAvatar="true"
            @change="loadImg"
          />
        </el-form-item>

        <el-form-item label="組織名稱設定" prop="name">
          <el-input v-model="formData.name" placeholder="請填寫" />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button plain @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">儲存</el-button>
      </div>
    </el-dialog>
    <ImageCropper
      v-if="uploadDialog"
      :image="uploadImg"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
  </div>
</template>

<script>
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import imageMixin from '@/mixin/image'
import formUtils from '@/utils/form'
import { noEmptyRules } from '@/validation'
import { mapGetters } from 'vuex'
import { UpdateOrgAuthConfig } from '@/api/org/org'

export default {
  name: 'AutoInvoiceDialog',
  components: { ImageCropper, UploadButton },
  mixins: [imageMixin],
  props: ['config'],
  computed: {
    ...mapGetters(['orgId']),
  },
  data: () => ({
    formData: {
      img: null,
      name: '',
    },
    formRules: {
      name: [noEmptyRules()],
    },
  }),
  mounted () {
    this.syncConfig()
  },
  methods: {
    async confirm () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      await this.updateOrgAuthConfig({ orgId: this.orgId })
      this.$emit('confirm')
      this.$emit('close')
    },

    cancel () {
      this.$emit('cancel')
      this.$emit('close')
    },

    async updateOrgAuthConfig () {
      const [res, err] = await UpdateOrgAuthConfig({
        orgId: this.orgId,
        orgName: this.formData.name || undefined,
        logo: this.formData.img?.id || undefined,
      })

      if (err) return this.$message.error(err)
      console.log(res)
      this.$message.success('更新成功！')
    },

    syncConfig () {
      const config = JSON.parse(JSON.stringify(this.config))
      this.formData.name = config.orgName || null
      this.formData.img = config.Logo || null
    },
  },
}
</script>

<style></style>
