<template>
  <el-dialog
    title="FB登入設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <el-form-item label="CallbackUrl" prop="callbackUrl">
        <span>{{ redirectURL }}</span>
      </el-form-item>

      <el-form-item label="應用程式編號" prop="appId">
        <el-input v-model="formData.appId" placeholder="請填寫" />
      </el-form-item>

      <el-form-item label="應用程式密鑰" prop="appSecret">
        <el-input v-model="formData.appSecret" placeholder="請填寫" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button plain @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">儲存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
export default {
  name: 'LineAuthSettingDialog',
  props: ['config', 'redirectURL', 'sync'],
  data: () => ({
    formData: {
      callbackUrl: '',
      appId: '',
      appSecret: '',
    },
    formRules: {
      appId: [noEmptyRules()],
      appSecret: [noEmptyRules()],
    },
  }),
  watch: {
    config () {
      this.syncConfig()
    },
  },

  mounted () {
    this.syncConfig()
  },
  methods: {
    async confirm () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      this.$emit('sync', this.formData)
      this.$emit('confirm')
      this.$emit('close')
    },

    cancel () {
      this.$emit('cancel')
      this.$emit('close')
    },

    syncConfig () {
      const config = JSON.parse(JSON.stringify(this.config))
      this.formData.appId = config.appID
      this.formData.appSecret = config.appSecret
    },
  },
}
</script>
