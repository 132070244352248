<template>
  <div>
    <el-dialog
      title="提示"
      :visible="true"
      width="400px"
      :close-on-click-modal="false"
      @close="$emit('cancel', type)"
    >
      <div>
        關閉「Line 登入設定」功能，將同步關閉「維持會員登入」功能。<br>
        確認關閉？
      </div>

      <div slot="footer">
        <el-button plain @click="$emit('cancel', type)">取消</el-button>
        <el-button type="primary" @click="$emit('confirm', type)">確認</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'LineLoginWarningDialog',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
}
</script>
