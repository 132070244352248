<template>
  <div class="oauth-user-info-setting">
    <PageTitle
      title="註冊設定"
      icon="chevron_left"
      hideBtn
      fontSize="20"
      @iconClick="$emit('close')"
    />

    <BasicTabs v-model="activeTab" :tabs="tabsOptions" />
    <div class="tab__content">
      <UserInfoSettingForm
        :activeType="activeTab"
        :formData="formData"
        @submit-setting="submitSetting"
        @close-user-info-setting="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import {
  mapGetters,
} from 'vuex'
import {
  UpdateUserInfoSetting,
} from '@/api/org/org'
import UserInfoSettingForm from '@/components/UserInfoSettingForm.vue'

export default {
  name: 'OAuthUserInfoSetting',

  components: {
    UserInfoSettingForm,
  },

  props: {
    formData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data: () => ({
    tabsOptions: [
      {
        value: 'base',
        label: '註冊資料固定欄位',
      },
      {
        value: 'customized',
        label: '註冊資料自定義欄位',
      },
    ],
    activeTab: 'base',
  }),

  computed: {
    ...mapGetters([
      'orgId',
    ]),
  },

  mounted () {
    document.querySelector('.views-container').scrollTop = 0
  },

  methods: {

    async updateUserInfoSetting (fields) {
      const [res, err] = await UpdateUserInfoSetting({
        orgId: this.orgId,
        fields,
      })
      if (err) {
        if (err.status === 404) return
        this.$message.error(err)
      } else {
        this.$message.success('註冊設定儲存完成')
      }
      return [res, err]
    },

    async submitSetting (submitFormData) {
      const [res, err] = await this.updateUserInfoSetting(submitFormData)
      if (err) return
      this.$emit('update:formData', res.fields)
      this.$emit('close')
    },
  },
}
</script>

<style lang="postcss" scoped>
.tabs {
  @apply ml-[30px];
}

.tab__content {
  @apply mt-6;
}
</style>
